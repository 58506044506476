import { Navigate, RouteObject } from 'react-router-dom';

import { DashboardView, ProductsView, SignInView } from './views/index.ts';

const routes: RouteObject[] = [
  { index: true, element: <Navigate to="/store" /> },
  {
    path: '/store',
    element: <DashboardView />
  },
  {
    path: '/auth/signin',
    element: <SignInView />
  },
  {
    path: '/products',
    element: <ProductsView />
  }
];

export { routes };
