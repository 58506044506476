import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const useSignInRedirect = Object.assign(
  () => {
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    params.set('next', location.pathname);

    return () => {
      navigate(`/auth/signin?${params}`);
    };
  },
  {
    useConsumeRedirect: () => {
      const navigate = useNavigate();
      const [params] = useSearchParams();

      return (defaultPath: string) => {
        const next = params.get('next');
        if (next !== 'string') {
          navigate(defaultPath);
          return;
        }
        navigate(next);
      };
    },
    buildRedirectUrl: (originalUrl: string) => {
      const params = new URLSearchParams();
      params.set('next', originalUrl);
      return `/auth/signin?${params}`;
    }
  }
);

export { useSignInRedirect };
