import Image1 from './OR0067_20Q_01.jpg';
import Image2 from './OR0067_20Q_02.jpg';
import Image3 from './OR0067_20Q_03.jpg';
import Image4 from './OR0067_20Q_04.jpg';
import Image5 from './OR0067_20Q_05.jpg';
import Image6 from './OR0067_20Q_06.jpg';
import Image7 from './OR0067_20Q_07.jpg';
import Image8 from './OR0067_20Q_08.jpg';

const SampleProductImages = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8
];

export { SampleProductImages };
