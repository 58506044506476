import {
  DefaultMantineColor,
  MantineProvider,
  MantineThemeOverride,
  Tuple
} from '@mantine/core';
import { Outlet } from 'react-router-dom';

import { BaseLayout } from './common/layouts/index.ts';

type AppColorNames =
  | 'shockingpink'
  | 'vividviolet'
  | 'violet'
  | 'fireopal'
  | 'antiflashwhite'
  | 'maxbluepurple'
  | 'white'
  | 'black'
  | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<AppColorNames, Tuple<string, 10>>;
  }
}

const AppTheme: MantineThemeOverride = {
  colors: {
    white: new Array(10).fill('#ffffff') as Tuple<string, 10>,
    black: new Array(10).fill('#000000') as Tuple<string, 10>,
    shockingpink: [
      '#ffe3ff',
      '#ffb2f4',
      '#fe81e9',
      '#fc4fe0',
      '#fa1ed6',
      '#f205cb',
      '#b00093',
      '#7e006a',
      '#4d0041',
      '#1e0019'
    ],
    vividviolet: [
      '#fce4ff',
      '#e9b3ff',
      '#d981fe',
      '#c84ffc',
      '#b81efa',
      '#AB05F2',
      '#7c02b0',
      '#59007f',
      '#36004e',
      '#15001e'
    ],
    violet: [
      '#f5e4ff',
      '#dab3ff',
      '#c081fe',
      '#a64ffc',
      '#8d1efa',
      '#7c05f2',
      '#5a02b0',
      '#40017f',
      '#26004e',
      '#0f001f'
    ],
    fireopal: [
      '#ffe4e4',
      '#feb6b7',
      '#f78788',
      '#f25252',
      '#ee2c2b',
      '#d41311',
      '#a60c0c',
      '#780708',
      '#490203',
      '#1f0000'
    ],
    antiflashwhite: [
      '#f2f2f2',
      '#d9d9d9',
      '#bfbfbf',
      '#a6a6a6',
      '#8c8c8c',
      '#737373',
      '#595959',
      '#404040',
      '#262626',
      '#0d0d0d'
    ],
    maxbluepurple: [
      '#e4e8ff',
      '#a9b0ff',
      '#818afd',
      '#505cfc',
      '#242efb',
      '#1216e2',
      '#0a11b0',
      '#040b7e',
      '#00064d',
      '#00021d'
    ]
  },
  primaryColor: 'maxbluepurple',
  fontFamily: '"Fredoka", sans-serif',
  globalStyles: (theme) => ({
    body: {
      backgroundColor: theme.colors.maxbluepurple[0]
    }
  })
};

const AppLayout = () => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={AppTheme}>
      <BaseLayout>
        <Outlet />
      </BaseLayout>
    </MantineProvider>
  );
};

export { AppLayout };
