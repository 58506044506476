import {
  AuthFlowType,
  CognitoIdentityProvider,
  InitiateAuthCommandOutput,
  RespondToAuthChallengeCommandOutput,
  ChallengeNameType
} from '@aws-sdk/client-cognito-identity-provider';
import { EventEmitter } from 'eventemitter3';
import dayjs, { Dayjs } from 'dayjs';

import {
  getJWTTokenClaims,
  getJWTTokenExp,
  isJWTTokenExpValid
} from '@juandavidkincaid/utils';

import {
  ICognitoAuthChallenge,
  ICognitoAuthChallengeParameters,
  ICognitoAuthFlowParameters,
  ICognitoAuthTokens
} from './types.ts';
import { CognitoPool } from './cognito_pool.ts';
import { CognitoPoolUser } from './cognito_pool_user.ts';

type CognitoPoolAuthFlowEvents = {
  authenticated: () => void;
  challenge: (challengeName: ChallengeNameType) => void;
};
class CognitoPoolAuthFlow<
  T extends AuthFlowType | 'HYDRATED'
> extends EventEmitter<CognitoPoolAuthFlowEvents> {
  protected challenges: ICognitoAuthChallenge<any>[];
  protected tokens: null | ICognitoAuthTokens;
  protected authenticatedAt: Dayjs | null;

  constructor(
    protected client: CognitoIdentityProvider,
    protected cognitoPool: CognitoPool,
    protected CognitoPoolAuthFlow: CognitoPoolUser,
    public readonly authFlow: T
  ) {
    super();
    this.challenges = [];
    this.tokens = null;
    this.authenticatedAt = null;
  }

  get isComplete() {
    return this.tokens !== null;
  }

  get isAuthenticated() {
    return (
      this.tokens !== null &&
      isJWTTokenExpValid(this.tokens.idToken) &&
      isJWTTokenExpValid(this.tokens.accessToken)
    );
  }

  get isHydrated() {
    return this.authFlow === 'HYDRATED';
  }

  get accessToken() {
    if (this.tokens === null || !this.isAuthenticated) {
      throw new Error('CognitoPoolAuthFlow: Auth flow is not authenticated');
    }
    return this.tokens.accessToken;
  }

  get accessTokenClaims() {
    if (this.tokens === null) {
      throw new Error('CognitoPoolAuthFlow: Auth flow is not authenticated');
    }
    return getJWTTokenClaims(this.tokens.accessToken);
  }

  get accessTokenExp() {
    if (this.tokens === null || !this.isAuthenticated) {
      throw new Error('CognitoPoolAuthFlow: Auth flow is not authenticated');
    }
    return getJWTTokenExp(this.tokens.accessToken);
  }

  get idToken() {
    if (this.tokens === null || !this.isAuthenticated) {
      throw new Error('CognitoPoolAuthFlow: Auth flow is not authenticated');
    }
    return this.tokens.idToken;
  }

  get idTokenClaims() {
    if (this.tokens === null) {
      throw new Error('CognitoPoolAuthFlow: Auth flow is not authenticated');
    }
    return getJWTTokenClaims(this.tokens.idToken);
  }

  get idTokenExp() {
    if (this.tokens === null || !this.isAuthenticated) {
      throw new Error('CognitoPoolAuthFlow: Auth flow is not authenticated');
    }
    return getJWTTokenExp(this.tokens.idToken);
  }

  get refreshToken() {
    if (this.tokens === null) {
      throw new Error(
        'CognitoPoolAuthFlow: Auth flow has not been authenticated before'
      );
    }
    return this.tokens.refreshToken;
  }

  get lastChallenge() {
    const lastChallenge = this.getLastChallenge();
    if (!lastChallenge) {
      throw new Error('CognitoPoolAuthFlow: No challenge is available');
    }
    return lastChallenge;
  }

  getLastChallenge(): ICognitoAuthChallenge<any> | null {
    if (this.isHydrated) {
      throw new Error(
        'CognitoPoolAuthFlow: Auth flow of type HYDRATED is not able to perform getLastChallenge'
      );
    }

    return this.challenges[this.challenges.length - 1] ?? null;
  }

  async initiateAuthChallenge(parameters: ICognitoAuthFlowParameters<T>) {
    if (this.isHydrated) {
      throw new Error(
        'CognitoPoolAuthFlow: Auth flow of type HYDRATED is not able to perform initiateAuthChallenge'
      );
    }

    if (this.isComplete) {
      throw new Error(
        'CognitoPoolAuthFlow: Auth flow was completed, you must create a new auth flow instance'
      );
    }

    const response = await this.client.initiateAuth({
      ClientId: this.cognitoPool.userPoolClientId,
      AuthFlow: this.authFlow,
      AuthParameters: parameters
    });

    return this.handleChallengeResponse(response);
  }

  async respondAuthChallenge<T extends ChallengeNameType>(
    authChallenge: T,
    parameters: ICognitoAuthChallengeParameters<T>
  ) {
    if (this.isHydrated) {
      throw new Error(
        'CognitoPoolAuthFlow: Auth flow of type HYDRATED is not able to perform respondAuthChallenge'
      );
    }

    if (this.isComplete) {
      throw new Error(
        'CognitoPoolAuthFlow: Auth flow was completed, you must create a new auth flow instance'
      );
    }

    const lastChallenge = this.getLastChallenge();

    if (!lastChallenge) {
      throw new Error('CognitoPoolAuthFlow: No challenge available to respond');
    }

    if (lastChallenge.name !== authChallenge) {
      throw new Error(
        'CognitoPoolAuthFlow: Mismatch on response to auth challenge name'
      );
    }

    const response = await this.client.respondToAuthChallenge({
      ClientId: this.cognitoPool.userPoolClientId,
      ChallengeName: lastChallenge.name,
      ChallengeResponses: parameters,
      Session: lastChallenge.session
    });

    return this.handleChallengeResponse(response);
  }

  protected handleChallengeResponse(
    result: InitiateAuthCommandOutput | RespondToAuthChallengeCommandOutput
  ) {
    if (result.AuthenticationResult) {
      this.tokens = {
        idToken:
          result.AuthenticationResult.IdToken ??
          (() => {
            throw new Error(
              'CognitoPoolAuthFlow: Invalid authentication result: id token'
            );
          })(),
        accessToken:
          result.AuthenticationResult.AccessToken ??
          (() => {
            throw new Error(
              'CognitoPoolAuthFlow: Invalid authentication result: access token'
            );
          })(),
        refreshToken:
          result.AuthenticationResult.RefreshToken ??
          (() => {
            throw new Error(
              'CognitoPoolAuthFlow: Invalid authentication result: refresh token'
            );
          })()
      };
      this.authenticatedAt = dayjs();
      this.emit('authenticated');
      return true;
    }

    this.challenges.push({
      name:
        (result.ChallengeName as ChallengeNameType) ??
        (() => {
          throw new Error(
            'CognitoPoolAuthFlow: Invalid authentication result: challenge name'
          );
        })(),
      session:
        result.Session ??
        (() => {
          throw new Error(
            'CognitoPoolAuthFlow: Invalid authentication result: challenge session'
          );
        })()
    });
    this.emit('challenge', result.ChallengeName as ChallengeNameType);

    return false;
  }

  serialize() {
    if (this.tokens === null) {
      throw new Error(
        'CognitoPoolAuthFlow: Unable to serialize with no tokens'
      );
    }

    if (this.authenticatedAt === null) {
      throw new Error(
        'CognitoPoolAuthFlow: Unable to serialize with no authenticated at date'
      );
    }

    return {
      tokens: this.tokens,
      authenticatedAt: this.authenticatedAt.toISOString()
    };
  }

  static isAuthFlow<T extends AuthFlowType>(
    authFlow: T,
    poolAuthFlow: CognitoPoolAuthFlow<any>
  ): poolAuthFlow is CognitoPoolAuthFlow<T> {
    return authFlow === poolAuthFlow.authFlow;
  }

  static fromTokens(
    client: CognitoIdentityProvider,
    cognitoPool: CognitoPool,
    cognitoPoolUser: CognitoPoolUser,
    serializedAuthFlow: ReturnType<CognitoPoolAuthFlow<any>['serialize']>
  ) {
    const authFlow = new this(client, cognitoPool, cognitoPoolUser, 'HYDRATED');
    authFlow.tokens = { ...serializedAuthFlow.tokens };
    authFlow.authenticatedAt = dayjs(serializedAuthFlow.authenticatedAt);
    return authFlow;
  }
}

export { CognitoPoolAuthFlow };
