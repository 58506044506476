import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { CognitoPool, CognitoPoolUser } from '@juandavidkincaid/cognito';

import { DeploymentRegions } from '@pyramo/infra';
import { EnvironmentContext } from '@pyramo/lib';

import { IAuthProviderContext } from './types.ts';

const AuthProviderContext = React.createContext<IAuthProviderContext | null>(
  null
);

const AuthProvider = ({
  children,
  userPoolId,
  userPoolClientId
}: {
  userPoolId: string;
  userPoolClientId: string;
  children: React.ReactNode;
}) => {
  const cognitoPool = useMemo(
    () =>
      new CognitoPool(
        'pyramo-auth',
        DeploymentRegions[EnvironmentContext.stage],
        userPoolId,
        userPoolClientId
      ),
    []
  );
  const [cognitoUser, setCognitoUser] = useState<CognitoPoolUser | null>(null);

  useEffect(() => {
    if (!cognitoUser) {
      setCognitoUser(cognitoPool.getStoreUser());
    }
  }, []);

  useEffect(() => {
    if (cognitoUser) {
      const logout = () => {
        cognitoPool.deleteStoreUser();
        setCognitoUser(null);
      };

      cognitoUser.on('refreshFlowFailure', logout);

      return () => {
        cognitoUser.off('refreshFlowFailure', logout);
      };
    }
  }, [cognitoUser]);

  useEffect(() => {
    if (cognitoUser && cognitoUser.isAuthenticated) {
      const toid = setTimeout(
        cognitoUser.getRefreshedAccessToken,
        cognitoUser.authFlow.accessTokenExp.diff(dayjs())
      );

      return () => {
        clearTimeout(toid);
      };
    }
  }, [cognitoUser]);

  const providerContext: IAuthProviderContext = {
    cognitoPool,
    cognitoUser,
    getUser: (username) => {
      const user = cognitoPool.getUser(username);
      user.on('authenticated', () => {
        setCognitoUser(user);
        cognitoPool.setStoreUser(user);
      });
      return user;
    },
    logoutUser: () => {
      cognitoPool.deleteStoreUser();
      setCognitoUser(null);
    }
  };

  return (
    <AuthProviderContext.Provider value={providerContext}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export { AuthProvider, AuthProviderContext };
