import {
  Button,
  Center,
  Flex,
  Grid,
  Image,
  MediaQuery,
  Paper,
  Text
} from '@mantine/core';

import { SampleProductImages } from '@/assets/sample-product/index.ts';

const Products = new Array(50).fill(null).map((_, idx) => ({
  name: `Adidas Product ${idx}`,
  description: `# ${idx} - With Fjord Tours you can explore more of the magical fjord landscapes with tours and activities on and around the fjords of Norway`,
  images: SampleProductImages
}));

const ProductsView = () => {
  return (
    <Flex direction={'column'} rowGap={'lg'}>
      {Products.map((product) => (
        <Paper p="sm">
          <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
            <Grid columns={4} align="center" justify="center">
              <Grid.Col span={1}>
                <Image src={product.images[0]} height={50} fit="contain" />
              </Grid.Col>
              <Grid.Col span={1}>
                <Text weight={500}>{product.name}</Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Text size="sm" color="dimmed">
                  {product.description}
                </Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Center>
                  <Button variant="light" color="maxbluepurple" radius="md">
                    Ver Producto
                  </Button>
                </Center>
              </Grid.Col>
            </Grid>
          </MediaQuery>
          <MediaQuery largerThan={'md'} styles={{ display: 'none' }}>
            <Grid columns={1} align="center" justify="center">
              <Grid.Col span={1}>
                <Image src={product.images[0]} height={50} fit="contain" />
              </Grid.Col>
              <Grid.Col span={1}>
                <Text weight={500}>{product.name}</Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Text size="sm" color="dimmed">
                  {product.description}
                </Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Center>
                  <Button variant="light" color="maxbluepurple" radius="md">
                    Ver Producto
                  </Button>
                </Center>
              </Grid.Col>
            </Grid>
          </MediaQuery>
        </Paper>
      ))}
    </Flex>
  );
};

export { ProductsView };
