import {
  Center,
  Grid,
  Image,
  Header as MantineHeader,
  Paper,
  Title
} from '@mantine/core';

import D8VLogoSvg from '@/assets/logos/logo-d8v.svg';

const Header = () => {
  return (
    <MantineHeader height={70}>
      <Paper>
        <Grid align="center">
          <Grid.Col span={2}>
            <Image src={D8VLogoSvg} height="60px" fit="contain" />
          </Grid.Col>
          <Grid.Col span="auto">
            <Center>
              <Title order={1}>D8V - Pyramo</Title>
            </Center>
          </Grid.Col>
          <Grid.Col span={2}></Grid.Col>
        </Grid>
      </Paper>
    </MantineHeader>
  );
};

export { Header };
