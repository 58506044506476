import { createProviderStateHook } from '@juandavidkincaid/utils';

import { AuthProvider, AuthProviderContext } from './provider.tsx';

const useAuthProvider = createProviderStateHook(
  AuthProvider,
  AuthProviderContext
);

export { useAuthProvider };
