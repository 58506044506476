const BuildContextTypeSymbol: unique symbol = Symbol('BuildContextType');

type BuildContext<T> = T & { [BuildContextTypeSymbol]: true };

const createBuildContext = <
  T extends Record<string, any>
>(): T extends BuildContext<infer X> ? BuildContext<X> : BuildContext<T> => {
  const properties: Partial<T> = {};

  return new Proxy(
    {},
    {
      get: (target, p) => {
        if (p === BuildContextTypeSymbol) {
          return true;
        }

        if (typeof p === 'symbol') {
          throw new Error(`symbols are not allowed for BuildContext objects`);
        }

        const value = properties[p];

        if (value === undefined) {
          throw new Error(
            `${p} has not been defined in context, make sure its used after definition`
          );
        }

        return value;
      },
      set: (target, p, newValue) => {
        if (typeof p === 'symbol') {
          throw new Error(`symbols are not allowed for BuildContext objects`);
        }

        const value = properties[p];

        if (value !== undefined) {
          throw new Error(
            `${p} has been defined in context, properties are not re-writable`
          );
        }

        (properties as any)[p] = newValue;
        return true;
      }
    }
  ) as T extends BuildContext<infer X> ? BuildContext<X> : BuildContext<T>;
};

export { BuildContextTypeSymbol, type BuildContext, createBuildContext };
