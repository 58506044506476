import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  MediaQuery,
  Text
} from '@mantine/core';

import { SampleProductImages } from '@/assets/sample-product/index.ts';

const Products = new Array(50).fill(null).map((_, idx) => ({
  name: `Adidas Product ${idx}`,
  description: `# ${idx} - With Fjord Tours you can explore more of the magical fjord landscapes with tours and activities on and around the fjords of Norway`,
  images: SampleProductImages
}));

const DashboardView = () => {
  return (
    <>
      <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
        <Grid columns={6}>
          {Products.map((product) => (
            <Grid.Col span={1}>
              <Card>
                <Card.Section>
                  <Image src={product.images[0]} height={160} />
                </Card.Section>
                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>{product.name}</Text>
                  {Math.random() - 0.5 > 0 && (
                    <Badge color="fireopal" variant="light">
                      Descuento
                    </Badge>
                  )}
                </Group>
                <Text size="sm" color="dimmed">
                  {product.description}
                </Text>
                <Button
                  variant="light"
                  color="maxbluepurple"
                  fullWidth
                  mt="md"
                  radius="md"
                >
                  Agregar Al Carrito
                </Button>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      </MediaQuery>
      <MediaQuery largerThan={'md'} styles={{ display: 'none' }}>
        <Grid columns={1}>
          {Products.map((product) => (
            <Grid.Col span={1}>
              <Card>
                <Card.Section>
                  <Image src={product.images[0]} height={160} />
                </Card.Section>
                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>{product.name}</Text>
                  {Math.random() - 0.5 > 0 && (
                    <Badge color="fireopal" variant="light">
                      Descuento
                    </Badge>
                  )}
                </Group>
                <Text size="sm" color="dimmed">
                  {product.description}
                </Text>
                <Button
                  variant="light"
                  color="maxbluepurple"
                  fullWidth
                  mt="md"
                  radius="md"
                >
                  Agregar Al Carrito
                </Button>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      </MediaQuery>
    </>
  );
};

export { DashboardView };
