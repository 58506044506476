import {
  Button,
  Center,
  Group,
  Image,
  Paper,
  PasswordInput,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';

import D8VLogoSvg from '@/assets/logos/logo-d8v.svg';

const SignInView = () => {
  const signInForm = useForm({ initialValues: { username: '', password: '' } });

  const doSignIn = signInForm.onSubmit(async (values) => {
    return;
  });

  return (
    <Center w="100%" h="100%">
      <Paper shadow="xs" p="md" maw={350} w={'100%'}>
        <Center w="100%">
          <Image src={D8VLogoSvg} maw={100} />
        </Center>
        <form onSubmit={doSignIn}>
          <TextInput
            label="Usuario"
            placeholder="your@email.com"
            {...signInForm.getInputProps('username')}
          />
          <PasswordInput
            label="Contraseña"
            {...signInForm.getInputProps('password')}
          />
          <Group position="center" mt="md" w="100%">
            <Button
              color="maxbluepurple"
              w="100%"
              type="submit"
              variant="light"
            >
              Ingresar
            </Button>
          </Group>
        </form>
      </Paper>
    </Center>
  );
};

export { SignInView };
