import {
  AuthFlowType,
  CognitoIdentityProvider,
  ChallengeNameType
} from '@aws-sdk/client-cognito-identity-provider';
import { EventEmitter } from 'eventemitter3';

import { CognitoPool } from './cognito_pool.ts';
import { CognitoPoolAuthFlow } from './cognito_pool_auth_flow.ts';

type CognitoPoolUserEvents = {
  authenticated: () => void;
  refreshFlowFailure: () => void;
};

class CognitoPoolUser extends EventEmitter<CognitoPoolUserEvents> {
  protected authFlows: CognitoPoolAuthFlow<any>[];
  protected refreshFlowFailure: boolean;

  constructor(
    protected client: CognitoIdentityProvider,
    protected cognitoPool: CognitoPool,
    public readonly username: string
  ) {
    super();
    this.authFlows = [];
    this.refreshFlowFailure = false;
  }

  get authFlow(): CognitoPoolAuthFlow<any> {
    if (this.authFlows.length === 0) {
      throw new Error('CognitoPoolUser: No auth flows are available');
    }

    return this.authFlows[this.authFlows.length - 1];
  }

  get isAuthenticated() {
    return this.authFlow?.isAuthenticated ?? false;
  }

  get isRefreshable() {
    return !this.refreshFlowFailure;
  }

  get claims(): Record<string, any> {
    return this.authFlow.idTokenClaims;
  }

  async getRefreshedAccessToken() {
    const currentAuthFlow = this.authFlow;

    if (currentAuthFlow.isAuthenticated) {
      return currentAuthFlow.accessToken;
    }

    if (!this.isRefreshable) {
      throw new Error(
        'CognitoPoolUser: Session is not refreshable, must re authenticate'
      );
    }

    let result: boolean;

    try {
      result = await this.initiateAuthFlow().refreshToken(
        currentAuthFlow.refreshToken
      );
    } catch (e) {
      console.error(e);
      result = false;
    }

    if (!result) {
      this.refreshFlowFailure = true;
      this.emit('refreshFlowFailure');
      throw new Error(
        'CognitoPoolUser: Unable to refresh tokens on first flow response'
      );
    }

    return this.authFlow.accessToken;
  }

  async forgotPassword() {
    await this.client.forgotPassword({
      ClientId: this.cognitoPool.userPoolClientId,
      Username: this.username
    });
  }

  async confirmForgotPassword(code: string, password: string) {
    await this.client.confirmForgotPassword({
      ClientId: this.cognitoPool.userPoolClientId,
      Username: this.username,
      ConfirmationCode: code,
      Password: password
    });
  }

  initiateAuthFlow() {
    return {
      refreshToken: async (refreshToken: string) => {
        const authFlow = new CognitoPoolAuthFlow(
          this.client,
          this.cognitoPool,
          this,
          AuthFlowType.REFRESH_TOKEN_AUTH
        );
        this.authFlows.push(authFlow);
        return authFlow.initiateAuthChallenge({ REFRESH_TOKEN: refreshToken });
      },
      userSRPAuth: async (srp_a: string) => {
        const authFlow = new CognitoPoolAuthFlow(
          this.client,
          this.cognitoPool,
          this,
          AuthFlowType.USER_SRP_AUTH
        );
        this.authFlows.push(authFlow);
        authFlow.on('authenticated', () => this.emit('authenticated'));
        return authFlow.initiateAuthChallenge({
          USERNAME: this.username,
          SRP_A: srp_a
        });
      },
      userPasswordAuth: async (password: string) => {
        const authFlow = new CognitoPoolAuthFlow(
          this.client,
          this.cognitoPool,
          this,
          AuthFlowType.USER_PASSWORD_AUTH
        );
        this.authFlows.push(authFlow);
        authFlow.on('authenticated', () => this.emit('authenticated'));
        return authFlow.initiateAuthChallenge({
          USERNAME: this.username,
          PASSWORD: password
        });
      }
    };
  }

  respondAuthFlow() {
    return {
      smsMFA: async (smsMFACode: string) => {
        return this.authFlow.respondAuthChallenge(ChallengeNameType.SMS_MFA, {
          USERNAME: this.username,
          SMS_MFA_CODE: smsMFACode
        });
      },
      passwordVerifier: async (
        claimSignature: string,
        claimSignatureBlock: string,
        timestamp: string
      ) => {
        return this.authFlow.respondAuthChallenge(
          ChallengeNameType.PASSWORD_VERIFIER,
          {
            USERNAME: this.username,
            PASSWORD_CLAIM_SIGNATURE: claimSignature,
            PASSWORD_CLAIM_SECRET_BLOCK: claimSignatureBlock,
            TIMESTAMP: timestamp
          }
        );
      },
      newPasswordRequired: async (newPassword: string) => {
        return this.authFlow.respondAuthChallenge(
          ChallengeNameType.NEW_PASSWORD_REQUIRED,
          {
            USERNAME: this.username,
            NEW_PASSWORD: newPassword
          }
        );
      },
      softwareTokenMFA: async (softwareTokenMFACode: string) => {
        return this.authFlow.respondAuthChallenge(
          ChallengeNameType.SOFTWARE_TOKEN_MFA,
          {
            USERNAME: this.username,
            SOFTWARE_TOKEN_MFA_CODE: softwareTokenMFACode
          }
        );
      },
      mfaSetup: async () => {
        return this.authFlow.respondAuthChallenge(ChallengeNameType.MFA_SETUP, {
          USERNAME: this.username
        });
      }
    };
  }

  serialize() {
    return { username: this.username, authFlow: this.authFlow.serialize() };
  }

  static fromTokens(
    client: CognitoIdentityProvider,
    cognitoPool: CognitoPool,
    serializedUser: ReturnType<CognitoPoolUser['serialize']>
  ) {
    const cognitoUser = new this(client, cognitoPool, serializedUser.username);

    const authFlow = CognitoPoolAuthFlow.fromTokens(
      client,
      cognitoPool,
      cognitoUser,
      serializedUser.authFlow
    );

    cognitoUser.authFlows.push(authFlow);

    return cognitoUser;
  }
}

export { CognitoPoolUser };
