import React from 'react';
import { AppShell } from '@mantine/core';

import { Header } from '../../components/index.ts';

const BaseLayout = ({ children }: { children: React.ReactNode }) => {
  return <AppShell header={<Header />}>{children}</AppShell>;
};

export { BaseLayout };
